const FEATURES_DATA_RU = [
  {
    name: "clients",
    color: "#319DED",
    title: "Учёт клиентов",
    description: "Управляйте <br/> клиентской базой",
    image: require("../../../../images/features/feature_clients.png"),
  },
  {
    name: "subscriptions",
    color: "#03C4A4",
    title: "Учет абонементов",
    description: "Создавайте абонементы из шаблонов",
    image: require("../../../../images/features/feature_abonements.png"),
  },
  {
    name: "analytics",
    color: "#6D20D2",
    title: "Аналитика",
    description: "Формируйте <br/> детальные отчеты",
    image: require("../../../../images/features/feature_reports.png"),
  },
  {
    name: "wages",
    color: "#F2A819",
    title: "Расчет зарплат",
    description: "Упростите <br/> расчет зарплат",
    image: require("../../../../images/features/feature_cash.png"),
  },
  {
    name: "portal",
    color: "#6D20D2",
    title: "Кабинет клиента",
    description: "Персонализируйте общение с клиентами",
    image: require("../../../../images/features/feature_messages.png"),
  },
  {
    name: "messengers",
    color: "#F2A819",
    title: "Мессенджеры",
    description: "Общайтесь в мессенджерах через систему",
    image: require("../../../../images/features/feature_messangers.png"),
  },
  {
    name: "widgets",
    color: "#03C4A4",
    title: "Виджеты",
    description: "Автоматизируйте <br/> сбор лидов", 
    image: require("../../../../images/features/feature_automatization.png"),
  },
  {
    name: "notifications",
    color: "#319DED",
    title: "Автоуведомления",
    description: "Информируйте клиентов и сотрудников",
    image: require("../../../../images/features/feature_information.png"),
  },
  {
    name: "tasks",
    color: "#E75D49",
    title: "Планировщик задач",
    description: "Ставьте задачи сотрудникам через планировщик",
    image: require("../../../../images/features/feature_tasks.png"),
  },
]

const FEATURES_DATA_EN = [
  {
    name: "clients",
    color: "#319DED",
    title: "Accounting for clients",
    description: "Manage your client base",
    image: require("../../../../images/features/feature_clients.png"),
  },
  {
    name: "subscriptions",
    color: "#03C4A4",
    title: "Subscription accounting",
    description: "Create subscriptions from templates",
    image: require("../../../../images/features/feature_abonements.png"),
  },
  {
    name: "analytics",
    color: "#6D20D2",
    title: "Analytics",
    description: "Generate detailed reports",
    image: require("../../../../images/features/feature_reports.png"),
  },
  {
    name: "wages",
    color: "#F2A819",
    title: "Payroll",
    description: "Simplify payroll",
    image: require("../../../../images/features/feature_cash.png"),
  },
  {
    name: "tasks",
    color: "#E75D49",
    title: "Tasks for employees",
    description: "Schedule tasks for employees",
    image: require("../../../../images/features/feature_tasks.png"),
  },
  {
    name: "portal",
    color: "#6D20D2",
    title: "Client Cabinet",
    description: "Personalize customer communications",
    image: require("../../../../images/features/feature_messages.png"),
  },
  {
    name: "messengers",
    color: "#F2A819",
    title: "Messengers",
    description: "Communicate in messengers through the system",
    image: require("../../../../images/features/feature_messangers.png"),
  },
  {
    name: "widgets",
    color: "#03C4A4",
    title: "Widgets",
    description: "Automate your lead collection", 
    image: require("../../../../images/features/feature_automatization.png"),
  },
  {
    name: "notifications",
    color: "#319DED",
    title: "Auto notifications",
    description: "Inform customers and employees",
    image: require("../../../../images/features/feature_information.png"),
  },
]

const FEATURES_DATA_DE = [
  {
    name: "clients",
    color: "#319DED",
    title: "Buchhaltung für Kunden",
    description: "Verwalten Sie Ihren Kundenstamm",
    image: require("../../../../images/features/feature_clients.png"),
  },
  {
    name: "subscriptions",
    color: "#03C4A4",
    title: "Abonnement-Abrechnung",
    description: "Erstellen Sie Abonnements aus Vorlagen",
    image: require("../../../../images/features/feature_abonements.png"),
  },
  {
    name: "analytics",
    color: "#6D20D2",
    title: "Analytik",
    description: "Generieren Sie detaillierte Berichte",
    image: require("../../../../images/features/feature_reports.png"),
  },
  {
    name: "wages",
    color: "#F2A819",
    title: "Lohn-und Gehaltsabrechnung",
    description: "Vereinfachen Sie die Gehaltsabrechnung",
    image: require("../../../../images/features/feature_cash.png"),
  },
  {
    name: "tasks",
    color: "#E75D49",
    title: "Task Manager",
    description: "Task Manager",
    image: require("../../../../images/features/feature_tasks.png"),
  },
  {
    name: "portal",
    color: "#6D20D2",
    title: "Kundenkabinett",
    description: "Kundenkommunikation personalisieren",
    image: require("../../../../images/features/feature_messages.png"),
  },
  {
    name: "messengers",
    color: "#F2A819",
    title: "Boten",
    description: "Kommunizieren Sie in Messengern über das System",
    image: require("../../../../images/features/feature_messangers.png"),
  },
  {
    name: "widgets",
    color: "#03C4A4",
    title: "Widgets",
    description: "Automatisieren Sie Ihre Lead-Sammlung", 
    image: require("../../../../images/features/feature_automatization.png"),
  },
  {
    name: "notifications",
    color: "#319DED",
    title: "Automatische Benachrichtigungen",
    description: "Kunden und Mitarbeiter informieren",
    image: require("../../../../images/features/feature_information.png"),
  },
]

export const getFeaturesList = (current, lang) => {
  const FEATURES_DATA = getFeaturesData(lang)
  const featureList = current
    ? [
      ...FEATURES_DATA.filter(feature => feature.name !== current),
      FEATURES_DATA.find(feature => feature.name === current),
    ]
    : FEATURES_DATA
  return featureList.slice(0, 8)
}

export const getFeaturesData = (lang) => {
  switch (lang) {
    case 'en':
      return FEATURES_DATA_EN
    case 'de':
      return FEATURES_DATA_DE
    default:
      return FEATURES_DATA_RU
  }
}
