import React from "react"

import styles from "./features.module.scss"
import FeaturesCard from "./features_card"

import { LangContext } from "../../../lang-context"

import { getFeaturesList } from "./features_data"

const FeaturesComponent = ({ current, title, isFeatureList, subtitle}) => (
  <div id='features'
    className={`container ${styles.features}`}>
    <div className={styles.head}>
      <h2 className={styles.title}>{isFeatureList ? "Дополнительные возможности" : title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
    <div className={styles.grid}>
      <LangContext.Consumer>
        {lang => getFeaturesList(current, lang).map(feature =>
          <FeaturesCard key={feature.name}
            className={feature.name === current ? styles.current : ""}
            isFeatureList={isFeatureList}
            {...feature}
          />)}
      </LangContext.Consumer>
    </div>
  </div>
)

export default FeaturesComponent
