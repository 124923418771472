import React, { useState } from "react"
import styles from "./accordion.module.scss"
import AnimateHeight from "react-animate-height"

const AccordionComponent = ({ data, title, descr }) => {
  const [tab, setTab] = useState(null)
  return <div className={`container ${styles.tabs} ${styles.featureTabs}`}>
    <div className={styles.head}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.descr}>{descr}</p>
    </div>
    {data.map(t =>
      <div key={t.name}
        className={`${styles.tab} ${t.name === (tab && tab.name) ? styles.open : ""}`}
        onClick={() => setTab(t !== tab ? t : null)}>
        <div className={styles.info}>
          <div className={styles.title}>{t.title}</div>
          <AnimateHeight duration={300} height={t.name === (tab && tab.name) ? "auto" : 0}>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: t.content }}></div>
          </AnimateHeight>
        </div>
        <div className={styles.chevron}>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
              fill="currentColor" />
          </svg>
        </div>
      </div>,
    )}
  </div>
}

export default AccordionComponent
