import React from "react"

import styles from "./hero-feature-page.module.scss"
import { openOverlay } from "../../../../../utils/overlay"
import Translate from "../../../../../i18n/Translate"
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Index = ({ title, description, tryNowCaption, color, image, btnDark, name, btnGreen, arrowDark, markDark, htmlDescription, htmlTitle }) => (
  <div
    className={styles.hero}
    style={{ backgroundColor: color }}>
    <div className={`container ${styles.container}`}>
      <div className={styles.info}>
        {htmlTitle
          ? <h1 className={`${styles.title} ${color === "#EDE8F9" ? styles.dark : ""}`} dangerouslySetInnerHTML={{ __html: htmlTitle }}></h1>
          : <h1 className={`${styles.title} ${color === "#EDE8F9" ? styles.dark : ""}`}>{title}</h1>
        }
        {htmlDescription
          ? <div className={`${styles.description} ${color === "#EDE8F9" ? styles.dark : ""}`} dangerouslySetInnerHTML={{ __html: htmlDescription }}></div>
          : <div className={`${styles.description} ${color === "#EDE8F9" ? styles.dark : ""}`} >{description}</div>
        }
        <div className={styles.ctaContainer}>
          {/*<AnchorLink offset={() => 60} href='#summer'><div
            className={`${styles.freeAccessOffer} ${color === "#E75D49" ? styles.freeAccessOfferContrast : ""} ${color === "#677C94" ? styles.freeAccessOfferYellow : ""} ${markDark ? styles.dark : ""}`}>
            <Translate id={"header.freeAccessLabel"}/>
          </div></AnchorLink>*/}
          <div className={`${styles.arrow} ${arrowDark ? styles.arrowdark : ""}`}>
            Попробуйте
          </div>
          <button className={`${styles.cta} ${btnDark ? styles.dark : ""} ${btnGreen ? styles.green : ""}  
          ${(name === "clients") || (name === "integration") || (name === "subscriptions") ? styles.yellowButton : ""} 
          ${(name === "portal") ? styles.red : ""}`}
            onClick={() => openOverlay(name, "hero", "trial")}>
            {tryNowCaption || "Бесплатный тест 10 дней"}
          </button>
        </div>
      </div>
      <div className={styles.image}>
        <img alt='Скриншот системы' src={image} />
      </div>
    </div>
  </div>
)

export default Index
