import React, { useState} from "react"
import styles from "./gallery.module.scss"
import AnimateHeight from "react-animate-height"

const GalleryComponent = ({ title, subtitle, data, galleryView, offShadow, rightImage, smallMargin, headerCenter }) => {
  const [tab, setTab] = useState(data[0])
  return <div className={`${styles.gallery} container`}>
    <div className={`${styles.header} ${headerCenter ? styles.headerCenter : ''}`}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
    <div className={`${styles.galleryContainer} ${rightImage === true ? styles.rightImage : ''} ${smallMargin === true ? styles.smallMargin : ''}`}>
      <div className={`${styles.image} ${offShadow === true ? styles.offShadow : ''}`}>
        {galleryView === 'video'
          ? <video key={tab.name} className={styles.videoTag} autoPlay loop muted><source src={tab.video} type='video/mp4' /></video>
          : <img src={tab.image} alt={tab.title}/>
        }
      </div>
      <div className={styles.tabs}>
        {data.map(t =>
          <div key={t.name}
               className={`${styles.tab} ${t.name === tab.name ? styles.open : ""}`}
               onClick={() => setTab(t)
               }>
            <div className={styles.chevron}>
              <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
                  fill="currentColor"/>
              </svg>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>{t.title}</div>
              <AnimateHeight duration={300} height={t.name === tab.name ? "auto" : 0}>
                <div 
                  className={styles.content} 
                  dangerouslySetInnerHTML={{__html: t.content}} 
                />
                <div className={`${styles.contentImage} ${offShadow === true ? styles.offShadow : ''} `}>
                  {galleryView === 'video'
                    ? <video key={t.name} className={styles.videoTag} autoPlay loop muted><source src={t.video} type='video/mp4' /></video>
                    : <img src={t.image} alt={t.title}/>
                  }
                </div>
              </AnimateHeight>
            </div>
          </div>,
        )}
      </div>
    </div>
  </div>
}

export default GalleryComponent
