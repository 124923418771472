import React from 'react';
import styles from "./custombreadcrumb.module.scss"

function Index(props) {

  function isLast(index) {
    return index === props.crumbs.length - 1;
  }

  function getColorArrow(arrowcolor) {
    switch (arrowcolor) {
      case 'blue':
        return styles.blue;
      case 'purple':
        return styles.purple;
      case 'green':
        return styles.green;
      case 'red':
        return styles.red;
      case 'yellow':
        return styles.yellow;
      default:
        return styles.purple;
    }
  }

  const arrowcolor = getColorArrow(props.arrowcolor);
  return (
    <nav className={styles.breadcrumb}>
      <ul>
        {
          props.crumbs.map((crumb, ci) => {
            const disabled = isLast(ci) ? styles.disabled : '';
            return (
              <li
                key={ ci } className={arrowcolor}
              >
                <a className={disabled} href={crumb.url}>
                  { crumb.name }
                </a>

              </li>
            );
          })
        }
      </ul>
    </nav>
  );
}

export default Index;