import React from "react"

import styles from "./about.module.scss"

function getBgClassName(styleName) {
  switch (styleName) {
    case "small":
      return styles.bgSmall
    case "wide":
      return styles.bgWide
    case "hanging-circle":
      return styles.bgHangingCircle
    case "center-oval":
      return styles.centerOval
    case "big-image":
      return styles.bgBigImage
    case "bigger-image":
      return styles.bgBiggerImage
    case "skewed-right":
      return styles.bgSkewedRight
    case "skewed-right-sm":
      return styles.bgSkewedRightSm
    case "skewed-left":
      return styles.bgSkewedLeft
    case "position-left":
      return styles.bgPositionLeft
    case "position-right":
      return styles.bgPositionRight
    case "position-bottom":
      return styles.bgPositionBottom
    case "position-top":
      return styles.bgPositionTop
    case "clear":
      return styles.clear
    default:
      return ""
  }
}

const AboutComponent = ({
                          title,
                          description,
                          image,
                          color,
                          reverse,
                          imageWidth,
                          imageHeight,
                          imageStyle,
                          bgSize,
                          bullets,
                          containerStyle,
                          alt,
                          infoSize,
                          imageSize,
                          circlePosition,
                          smallMargin,
                        }) => (
  <div className={`container ${styles.feature} ${reverse ? styles.reverse : ""} ${smallMargin ? styles.smallMargin : ""}`}
       style={containerStyle}>
    <div className={styles.info} style={{ flexBasis: infoSize || null }}>
      {
        ((title === 'Уведомления <br/> клиентов и лидов') || (title === 'Уведомления <br/> сотрудников'))
          ? <h3 className={styles.title} dangerouslySetInnerHTML={{__html: title}}></h3>
          : <h2 className={styles.title} dangerouslySetInnerHTML={{__html: title}}></h2>
      }
      {
        !bullets
          ? <p className={styles.description} dangerouslySetInnerHTML={{__html: description}}></p>
          : <ul className={styles.bullets}>
            {bullets.map(bullet => <li key={bullet}>{bullet}</li>)}
          </ul>
      }
    </div>
    <div className={styles.image}>
      <div className={styles.wrapper}>
        <div
          className={`${styles.background} ${getBgClassName(bgSize)}`}
          style={{ backgroundColor: color, flexBasis: imageSize || null, top: circlePosition + 'px'}}/>
        <img
          alt={alt || "Скриншот системы"}
          className={styles.screenshot}
          width={imageWidth}
          height={imageHeight}
          style={imageStyle}
          src={image}/>
      </div>
    </div>
  </div>
)

export default AboutComponent