import React from "react"

import styles from "./features_card.module.scss"
import { withLanguage } from "../../../../i18n/with-language"
import { Link } from "gatsby"

const FeaturesCard = ({ lang, image, description, color, name, className, title, isFeatureList }) => {
  const Wrapper = lang === "ru" ? Link : "div"
  const isSSR = typeof window === "undefined"
  if(!isSSR) {
    let portallink = sessionStorage.getItem('portal');
    let subscriptionslink = sessionStorage.getItem('subscriptions');

    if ((name === 'portal') && (portallink)) {
      name = portallink
    } else if ((name === 'subscriptions') && (subscriptionslink)) {
      name = subscriptionslink
    }
  }

    return (
      <Wrapper to={`/feature/${name}`} className={`${styles.features_card} ${className}`}>
        <div
          className={styles.image}
          style={{ backgroundColor: color }}>
          <img alt={description} src={image}/>
        </div>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: isFeatureList ? title : description }}></div>
      </Wrapper>
    )
}

export default withLanguage(FeaturesCard)
