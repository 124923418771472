import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import AboutComponent from "../../components/pages/main/about"
import FeaturesComponent from "../../components/pages/main/landing-features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import Head from "../../components/pages/features/head"
import GalleryComponent from "../../components/pages/features/gallery"
import FeaturePriceCards from "../../components/pages/features/feature-price"
import FeatureAccordion from "../../components/pages/main/accordion"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';


const onlinecashboxAccordionData = [
  {
    name: "module",
    title: "Как онлайн-касса “Модуль Касса” работает с Параплан CRM?",
    content: `
      <ol>
        <li>Вы вносите новый платеж клиенту в Параплан через кнопку “Внести платеж” во вкладке “Платежи” в карточке клиента.</li>
        <li>В интерфейсе Параплан CRM отдельно формируете чек на полученную сумму через окошко “Онлайн-касса”.</li>
        <li>Параплан передает данные в сервис “Модуль Касса”, а он, в свою очередь, отправляет данные на фискальный накопитель (т.е. фискализирует платеж для налоговой службы).</li>
        <li>Платеж принят в CRM, а чек "выбит" на ККМ - его можно отдать клиенту или чек будет отправлен по email.</li>
      </ol>  
      <p>Если ученик пополняет баланс в своем Личном кабинете Параплана через интернет-эквайринг PAW, который связан с онлайн-кассой Модуль Касса, то в Параплане выбитый чек появится в разделе финансы <b>автоматически</b>. И выбивать его вручную не нужно.</p>
      <p><b>ВАЖНО!</b> Параплан CRM только передает данные, которые Вы вносите в системе, напрямую в онлайн-кассу. Вам необходимо настроить по инструкции ниже интеграцию самого сервиса “Модуль Касса” и Параплан.</p>
    `,
  },
  {
    name: "kkm",
    title: "Нужно ли иметь ККМ для печати чеков, если я принимаю деньги наличными?",
    content: `Да, вам необходимо иметь ККМ для печати чеков, если вы принимаете деньги наличными от физических лиц. Даже если вы работаете без онлайн-кассы, вам нужно выдать покупателю закрывающий документ, например, чек или БСО.`,
  },
  {
    name: "cashbox",
    title: 'Нужно ли иметь физическую кассу в офисе, если я принимаю платежи от клиентов только "онлайн" в Личном кабинете клиентов?',
    content: `Нет, в этом случае иметь физическую кассу не обязательно. Однако у вас должен быть подключен Интернет-эквайринг с привязкой к онлайн-кассе.`,
  },
]

const OnlinecashboxFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Онлайн-оплата', url: ''}
  ];
  return (
    <Layout page={"onlinecashbox"} headerColor={"#E75D49"}>
      <SEO
        title="Онлайн-касса и прием платежей в CRM-системе Параплан"
        description="Автоматизация платежей в CRM-системе. Принимайте платежи и формируйте чеки онлайн с возможностью быстрой отправки клиенту или в налоговую."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"onlinecashbox"}
        title='CRM-система с онлайн-кассой'
        description='Моментально отправляйте чек своим покупателям и в налоговую, используя онлайн-кассу в CRM.'
        color='#E75D49'
        btnDark={true}
        image={require("../../images/cover/onlinecashbox.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"red"}/>
      <Head
            center={true}
            title="Интеграция с онлайн-кассой “Модуль Касса”"
            subtitle="Подключите быстрый и прозрачный инструмент для учета онлайн платежей."
          />
      <AboutComponent
        title='Оформление чеков'
        description='Создавайте чек после любой финансовой операции: покупка абонемента или пробного занятия, товара, оплата долга или возврат средств. Проводите кассовые операции в соответствии с Законом № 54-ФЗ.'
        image={require("../../images/screenshot/onlinecashbox/check.png")}
        imageWidth={676}
        imageHeight={364}
        bgSize={"position-bottom"}
        reverse={true}
        color='#03C4A4' />
      <AboutComponent
        title='Отправка чеков'
        description='Отправляйте клиентам чек автоматически на email после пополнения баланса в Личном кабинете. Каждый пробитый чек онлайн-кассу CRM мгновенно передается для учета в налоговую. Минимум бумажных документов.'
        image={require("../../images/screenshot/onlinecashbox/send-check.png")}
        imageWidth={676}
        imageHeight={364}
        bgSize={"position-bottom"}
        color='#319DED' />
      <Discount />
      <AboutComponent
        title='История чеков'
        description='Находите нужные чеки во вкладке “Чеки” раздела “Финансы”. Быстро ищите документ по периоду. Вы всегда сможете продублировать чек клиенту, скачав его в онлайн-кассе.'
        image={require("../../images/screenshot/onlinecashbox/history.png")}
        imageWidth={676}
        imageHeight={364}
        bgSize={"position-bottom"}
        reverse={true}
        color='#FFC800' />
      <FeatureAccordion
        data={onlinecashboxAccordionData}
        title='Это важно знать'
        descr='CRM-система Параплан работает напрямую только с одной онлайн-кассой - “Модуль Касса”.'
      />
      <GalleryComponent
        title={"Фискализация онлайн платежей"}
        subtitle={"Принимайте оплаты онлайн в соответствии с законом."}
        galleryView={"img"}
        headerCenter={true}
        offShadow={true}
        smallMargin={true}
        data={[
          {
            name: "how",
            title: "Как это работает",
            content: "Фискализация платежей - это возможность отправки чеков на почты клиентов после пополнения баланса в Личном кабинете и передачи информации о совершенной оплате в налоговую. Единственное отличие фискализации платежей от онлайн-кассы — нет возможности самостоятельно формировать чеки в Параплан.",
            image: require("../../images/screenshot/onlinecashbox/gallery_how.png"),
          },
          {
            name: "services",
            title: "Доступные сервисы",
            content: "Вы можете настроить фискализацию платежей через один из двух сервисов: КОМТЕТ Касса и МодульКасса. Обращаем внимание, что оба сервиса доступны для интернет-эквайрингов Тинькофф, Сбер, PayAnyWay.",
            image: require("../../images/screenshot/onlinecashbox/gallery_services.png"),
          },
        ]}
      />
      <Head
            center={true}
            title="Интеграция с Интернет-эквайрингом"
            subtitle="Откройте клиентам возможность оплачивать услуги онлайн."
      />
      <AboutComponent
        title='Прием оплаты онлайн'
        description='Подключите Личные кабинеты, которые интегрированы с Интернет-эквайрингом. Ваши клиенты смогут пополнять баланс онлайн в своем кабинете. В Параплан CRM доступны три Интернет-эквайринга: СберБанк, Тинькофф, PayAnyWay.'
        image={require("../../images/screenshot/onlinecashbox/online-payment.png")}
        imageWidth={676}
        imageHeight={364}
        bgSize={"position-top"}
        color='#319DED' />
      <FeaturePriceCards
        pageName="onlinecashbox"
        title="Подключите помощников для работы с платежами!"
        text="Стоимость интеграций с онлайн-кассой и Интернет-эквайрингом"
      />
      <FeaturesComponent
        title="Дополнительные возможности"
        current={"onlinecashbox"} />
      <ContactFormComponent />
    </Layout>
  )
}

export default OnlinecashboxFeaturePage
