import React from "react"
import styles from "./head.module.scss"

const FeaturesHead = ({ title, subtitle, withoutMargin, center }) => (
    <div className={`container`}>
        <div className={`${styles.head} ${withoutMargin ? styles.withoutMargin : ''} ${center ? styles.center : ''}`}>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></h2>
            <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }}></div>
        </div>
    </div >
)

export default FeaturesHead